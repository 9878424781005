import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

const SignContainer = styled.div`
  max-height: 30vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

const TryAgainButton = styled.button ` 
  width: 50%;
  padding-left: 40px;
`;

const ErrorMessage = () => {
  const navigate = useNavigate();

  const handleOnClick = () => {
    navigate('/intro');
  };

  return (
    <>
      <SignContainer>
        <h1>There has been an error reading this sign.</h1>
        {/* Add a google analytics log here */}
      </SignContainer>
      <TryAgainButton onClick={handleOnClick}>Try again</TryAgainButton>
    </>
  );
};

export default ErrorMessage;