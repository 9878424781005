import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../providers/authProvider';
import { signInWithGooglePopup } from '../utils/firebase';
import { useNavigate } from 'react-router-dom';

const SignInButton: React.FC = () => {
  const { loading, user } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      navigate('/intro');
    }
  }, [loading]);

  const logGoogleUser = async () => {
    const response = await signInWithGooglePopup().catch((error) => {
      console.error('Caught error Popup closed');
    });
    navigate('/intro');
  };
  return (
    <div>
      <button onClick={logGoogleUser}>Sign In With Google</button>
    </div>
  );
};

export default SignInButton;
