const daysOfWeek = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const monthsOfYear = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getDaySuffix = (day: number) => {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const getCurrentDate = (now: Date) => {
  const dayOfWeek = daysOfWeek[now.getDay()];
  const monthOfYear = monthsOfYear[now.getMonth()];

  const day = now.getDate();
  const formatedDate = day.toString().padStart(2, '0');
  return `${dayOfWeek}, ${monthOfYear} ${formatedDate}${getDaySuffix(day)}`;
};

export const getCurrentTime = (now: Date) => {
  let hours = now.getHours();
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12 || 12; // Convert hours to 12-hour format
  return `${hours}.${minutes} ${ampm}`;
};

export const getTimeWithTimezone = (): string => {
  const now = new Date();
  const timezoneOffset = now.getTimezoneOffset(); // Get the timezone offset in minutes
  return new Date(now.getTime() - timezoneOffset * 60000).toISOString(); // Adjust for timezone offset
};
