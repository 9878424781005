import { createBrowserRouter } from 'react-router-dom';
import IntroLayout from '../layouts/introLayout';
import ParkingCheck from '../controllers/parkingCheck';
import MainLayout from '../layouts/mainLayout';
import Intro from '../controllers/intro';
import Login from '../controllers/login';
import PrivateRoute from './privateRoute';

const router = () => {
  return createBrowserRouter([
    {
      element: <IntroLayout />,

      children: [
        {
          path: '/',
          element: <Login />,
        },
        {
          path: '/Intro',
          element: (
            <PrivateRoute>
              <Intro />
            </PrivateRoute>
          ),
        },
      ],
    },
    {
      element: <MainLayout shouldDisplayFooter={true} />,
      children: [
        {
          path: '/check-parking',
          element: (
            <PrivateRoute>
              <ParkingCheck />
            </PrivateRoute>
          ),
        },
      ],
    },
  ]);
};

export default router;
