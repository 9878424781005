import { RouterProvider } from 'react-router-dom';
import router from './core/route';
import GlobalStyles from './components/globalStyles';
import { AuthProvider } from './providers/authProvider';

const App = () => {
  return (
    <>
      <GlobalStyles />
      <AuthProvider>
        <RouterProvider router={router()} />
      </AuthProvider>
    </>
  );
};

export default App;
