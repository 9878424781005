import { css, Global } from '@emotion/react';
import chevronWhite from '../assets/chevron-white.svg';
import chevronPurple from '../assets/chevron-purple.svg';

const GlobalStyles: React.FunctionComponent = () => {
  return (
    <Global
      styles={css`
        @import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
        
        body {
          margin: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p {
          font-family: 'Inter', sans-serif;
        }

        h3 {
          font-size: 2.9rem;
          font-weight: 300;
          .bold {
            font-weight: 600;
          }
        }

        h4 {
          font-size: 2.25rem;
          font-weight: 300;
          .bold {
            font-weight: 600;
          }
        }

        h5 {
          font-size: 1.15rem;
          font-weight: 500;
        }

        h6 {
          font-size: 1.5rem;
          font-weight: 300;
          .bold {
            font-weight: 700;
          }
        }

        p {
          font-size: 1rem;
          font-weight: 300;
          .bold {
            font-weight: 700;
          }
        }

        button {
          display: flex;
          background-color: #fff;
          width: 100%;
          height: 40px;
          border: 0;
          border-radius: 40px;
          color: #9747ff;
          font-size: 1.15rem;
          flex-wrap: nowrap;
          flex-direction: row;
          align-items: center;
          justify-content: space-evenly;
          position: relative;
          padding: 0px 20px;
          width: 230px;
        }

        button:hover {
          background-color: #9747ff;
          color: #fff;
        }

        button:hover:after {
          content: url('${chevronWhite}');
          padding-left: 15px;
        }

        button::after {
          padding-left: 15px;
          content: url('${chevronPurple}');
        }
        
      `}
    />
  );
};
export default GlobalStyles;
