import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyA7v0cZtlOyIRzpZAzaJu-9YzLiSOBCsKw',
  authDomain: 'parkey-d3a70.firebaseapp.com',
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export default auth;
