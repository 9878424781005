import styled from '@emotion/styled';
import ParkingSideButtons from './parkingSideButtons';
import RefreshSignButton from '../components/refreshSignButton';

const SignContainer = styled.div`
  max-height: 30vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
`;

const SignImage = styled.img`
  display: flex;
  align-self: flex-start;
  border-radius: 20px;
  max-height: 30vh;
`;

const StyledText = styled.h4`
  margin: 3vh 0;
`;

interface Props {
  signPhoto: string;
  setParkingSide: React.Dispatch<React.SetStateAction<string | null>>;
  resetSignPhoto: React.Dispatch<React.SetStateAction<string | null>>;
}

const ParkingDetails: React.FC<Props> = (props: Props) => {
  const { signPhoto, resetSignPhoto, setParkingSide } = props;

  return (
    <>
      <SignContainer>
        <SignImage src={signPhoto} alt="sign" />
        <RefreshSignButton resetSignPhoto={resetSignPhoto} />
      </SignContainer>
      <StyledText>
        Are you parked to the <span className="bold">left</span> or{' '}
        <span className="bold">right</span> of this sign?
      </StyledText>
      <ParkingSideButtons setParkingSide={setParkingSide} />
    </>
  );
};

export default ParkingDetails;
