export const stringToInt = (stringValue: string): number | null => {
  try {
    const intValue = parseInt(stringValue, 10);
    if (isNaN(intValue)) {
      throw new Error('Conversion failed: Not a valid integer');
    }
    return intValue;
  } catch (error) {
    console.error('Error:', (error as Error).message);
    return null;
  }
};
