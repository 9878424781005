import styled from '@emotion/styled';
import { useNavigate } from 'react-router-dom';
import CameraInput from '../components/cameraInput';
import { useEffect, useMemo, useState } from 'react';
import ParkingDetails from '../composites/parkingDetails';
import ParkingStatus from '../composites/parkingStatus';
import LoadingAnimation from '../composites/loadingAnimation';
import ErrorMessage from '../composites/errorMessage';
import { getTimeWithTimezone } from '../utils/time';
import { ParkingData } from '../core/interface';
import { getUserToken } from '../utils/firebase';

const SignContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  text-align: left;
  color: white;
  border-radius: 0 0 100px 0;
`;

enum ActiveScreen {
  TAKE_PHOTO = 'takePhoto',
  ENTER_PARKING_DETAILS = 'enterParkingDetails',
  PARKING_STATUS = 'parkingStatus',
  API_ERROR = 'apiError',
  NO_PARKING_SIGN_PHOTO = 'noParkingSignPhoto',
  LOADING = 'loading',
}

interface ApiResponse {
  data: ParkingData;
  status: string;
}

const ParkingCheck: React.FC = () => {
  const navigate = useNavigate();
  const [activeScreen, setActiveScreen] = useState<ActiveScreen>(
    ActiveScreen.TAKE_PHOTO
  );
  const [signPhoto, setSignPhoto] = useState<string | null>(null);
  const [parkingSide, setParkingSide] = useState<string | null>(null);
  const [data, setData] = useState<ParkingData | null>(null);

  useEffect(() => {
    if (signPhoto) {
      setActiveScreen(ActiveScreen.ENTER_PARKING_DETAILS);
      return;
    }
    setActiveScreen(ActiveScreen.TAKE_PHOTO);
  }, [signPhoto]);

  const getBody = () => {
    return JSON.stringify({
      image: signPhoto,
      data: {
        parkingDetails: {
          timestamp: getTimeWithTimezone(),
          side: parkingSide,
        },
      },
    });
  };

  // If parkingSide and photo are set,
  // navigate to the parking status screen
  useEffect(() => {
    if (parkingSide) {
      const fetchData = async () => {
        const token = await getUserToken();
        const response = await fetch(
          'http://127.0.0.1:5001/parkey-d3a70/us-central1/api',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${token}`,
            },
            body: getBody(),
          }
        );
        const json = (await response.json()) as ApiResponse;
        if (json.status !== 'success') {
          setActiveScreen(ActiveScreen.API_ERROR);
          return;
        }
        const data = json.data;
        if (!data.success) {
          setActiveScreen(ActiveScreen.NO_PARKING_SIGN_PHOTO);
          return;
        }
        setData(json.data);
        setActiveScreen(ActiveScreen.PARKING_STATUS);
      };
      setActiveScreen(ActiveScreen.LOADING);
      fetchData();
      return;
    }
  }, [parkingSide]);
  
  const renderScreen = useMemo(() => {
    switch (activeScreen) {
      case ActiveScreen.TAKE_PHOTO:
        return <CameraInput setSignPhoto={setSignPhoto} />;
      case ActiveScreen.ENTER_PARKING_DETAILS:
        return (
          signPhoto && (
            <ParkingDetails
              signPhoto={signPhoto}
              resetSignPhoto={setSignPhoto}
              setParkingSide={setParkingSide}
            />
          )
        );
      case ActiveScreen.PARKING_STATUS:
        return data && <ParkingStatus data={data} />;

      case ActiveScreen.API_ERROR:
        return <ErrorMessage />;
      case ActiveScreen.NO_PARKING_SIGN_PHOTO:
        return <ErrorMessage />;;
        case ActiveScreen.LOADING:
          return <LoadingAnimation />;
      default:
        return <CameraInput setSignPhoto={setSignPhoto} />;
    }
  }, [activeScreen]);

  return <SignContent>{renderScreen}</SignContent>;
};

export default ParkingCheck;
