import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import firebase from 'firebase/app';
import 'firebase/auth';
import auth from '../services/firebaseConfig';

// const firebaseConfig = {
//   apiKey: 'AIzaSyA7v0cZtlOyIRzpZAzaJu-9YzLiSOBCsKw',
//   authDomain: 'parkey-d3a70.firebaseapp.com',
// };

// Initialize Firebase
// const firebaseApp = initializeApp(firebaseConfig);
// Initialize Firebase Auth provider
const provider = new GoogleAuthProvider();

// whenever a user interacts with the provider, we force them to select an account
provider.setCustomParameters({
  prompt: 'select_account ',
});
// export const auth = getAuth();
export const signInWithGooglePopup = () =>
  signInWithPopup(auth, provider)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!credential) {
        console.error('no credentials');
        return null;
      }
      // The signed-in user info.
      const user = result.user;
    })
    .catch((error) => {
      // Handle Errors here.
      const errorMessage = error.message;
      const credential = GoogleAuthProvider.credentialFromError(error);
      console.error('error', errorMessage, credential);
      return null;
    });

export const getUserToken = async (): Promise<string | null> => {
  const user = auth.currentUser;
  let token = null;
  if (!user) {
    return null;
  }
  try {
    token = await user.getIdToken();
  } catch (error) {
    console.error('Error getting user token:', error);
  }
  return token;
};
