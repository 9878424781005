import styled from '@emotion/styled';
import back from '../assets/backbutton.svg';
import { useNavigate } from 'react-router-dom';
import { useContext, useState, useEffect, useCallback } from 'react';
import { signOut } from 'firebase/auth';
import auth from '../services/firebaseConfig';
import userLogin from '../assets/user-login.svg';
import { AuthContext } from '../providers/authProvider';
import { signInWithGooglePopup } from '../utils/firebase';

const HeaderStyled = styled.header`
  height: 35px;
  color: #9747ff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
`;

const BackButton = styled.img<{ show: boolean }>`
  margin-right: auto;
  margin-left: 10px;
  cursor: pointer;
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

const UserLoginImage = styled.img`
  width: 20px;
  margin: 2.5px 10px 0 0;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.1);
  }
`;

const DropdownMenu = styled.div`
  position: absolute;
  right: 0px;
  top: 35px;
  background-color: white;
  border: 1px solid #9747ff;
  width: 200px;
  z-index: 1000;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  font-family: 'Inter', sans-serif;
  color: #9747ff;

  & > div {
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
      background-color: #f1f1f1;
    }
  }
`;

const Email = styled.div`
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AppName = styled.h5`
  flex-grow: 1;
  margin: 0 0 0 -10px;
`;

const SignInOption = styled.div`
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const Header: React.FC = () => {
  const { user, loading } = useContext(AuthContext);
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const handleGoBackToMainPage = useCallback(() => {
    if (user) {
      navigate('/intro');
      return;
    }
    navigate('/');
  }, [user, navigate]);

  const toggleDropdown = useCallback(() => {
    setShowDropdown((prevShowDropdown) => !prevShowDropdown);
  }, []);

  const handleSignIn = async () => {
    try {
      await signInWithGooglePopup();
      setShowDropdown(false);
      navigate('/intro');
    } catch (error) {
      console.error('Sign in failed:', error);
      navigate('/');
    }
  };

  const handleSignOut = useCallback(() => {
    signOut(auth)
      .then(() => {
        handleGoBackToMainPage();
        setShowDropdown(false);
      })
      .catch((error) => {
        console.error('Error signing out:', error);
      });
  }, [handleGoBackToMainPage]);

  const IsHomePage = (): boolean => {
    return window.location.pathname !== '/';
  };

  return (
    <HeaderStyled>
      <BackButton
        show={IsHomePage()}
        src={back}
        alt="back"
        onClick={() => window.history.back()}
      />
      <AppName onClick={handleGoBackToMainPage}>P A R K E Y</AppName>
      {loading ? (
        <span> </span>
      ) : user ? (
        <>
          <UserLoginImage
            src={userLogin}
            alt="User Login"
            onClick={toggleDropdown}
          />
          {showDropdown && (
            <DropdownMenu>
              <Email>{user.email}</Email>
              <div onClick={handleSignOut}>Sign Out</div>
            </DropdownMenu>
          )}
        </>
      ) : (
        <>
          <UserLoginImage
            src={userLogin}
            alt="Not Logged In"
            onClick={toggleDropdown}
          />
          {showDropdown && (
            <DropdownMenu>
              <SignInOption onClick={handleSignIn}>Sign In</SignInOption>
            </DropdownMenu>
          )}
        </>
      )}
    </HeaderStyled>
  );
};

export default Header;
