import React, { useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';
import info from '../assets/info.svg';
import yellowSign from '../assets/yellowsign.png';
import greenSign from '../assets/greensign.png';
import redSign from '../assets/redsign.png';
import { ParkingData } from '../core/interface';
import { stringToInt } from '../utils/utils';

interface ModalOverlayProps {
  show: boolean;
}

interface LightImageProps {
  imageName: string;
}

const Signs: { [key: string]: string } = {
  green: greenSign,
  yellow: yellowSign,
  red: redSign,
};

const EmojiButtonContainer = styled.div`
  display: flex;
  margin-top: 10px;
`;

const EmojiButton = styled.span`
  font-size: 20px;
  cursor: pointer;
  margin-right: 8px;
  background-color: rgba(80, 33, 255, 0.48);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
`;

const ModalOverlay = styled.div<ModalOverlayProps>`
  height: 100vh;
  top: 0;
  position: absolute;
  width: 100vw;
  left: 0;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.7);
`;

const ModalContent = styled.div`
  display: inline-block;
  text-decoration: none;
  padding: 50px 40px;
  background-color: #fff;
  border-radius: 3px;
  color: #585858;
  font-family: 'Roboto', sans-serif;
  width: 70vw;
`;

const CloseButton = styled.span`
  float: right;
  font-size: 28px;
  cursor: pointer;
  margin-top: -45px;
  margin-right: -25px;
`;

const AccuracyButton = styled.h4`
  float: right;
  font-size: 20px;
  cursor: pointer;
  margin-top: 0px;
  margin-right: -25px;
  width: 60%;
`;

const ImageStyled = styled.img`
  width: 30px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 15px;
  margin-bottom: -5px;
`;

const Result = styled.h3`
  margin-top: 0px;
`;

const Date = styled.h6`
  font-size: 1.85rem;
  font-weight: 300;
`;

const Container = styled.div`
  margin-top: -35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 85vw;
  z-index: 10000;
`;

const LightImage = styled.div<LightImageProps>`
  display: flex;
  width: 100%;
  min-height: 85vh;
  position: absolute;
  border-radius: 0 0 100px 0;
  background: url('${(props) => {
    return props.imageName;
  }}');
  background-size: 12vh;
  background-repeat: no-repeat;
  background-position: right 30% bottom 0px;
`;

interface Props {
  data: ParkingData;
}

const ParkingStatus: React.FC<Props> = (props: Props) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [isFeedbackSend, setIsFeedbackSend] = useState(false);

  const { data } = props;
  const { timestamp } = data;

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const getLightImage = useMemo(() => {
    if (data.isItNoStoppingZone) {
      return Signs.red;
    }

    if (data.isthereAParkingRestriction) {
      return Signs.yellow;
    }

    return Signs.green;
  }, [data]);

  const handleFeedback = (type: 'positive' | 'negative') => {
    console.log(`Feedback given: ${type}`); // Logs feedback to the console
    setIsFeedbackSend(true);
  };

  // You can add more logic here, like sending the feedback to a server
  const renderRestrictions = useMemo(() => {
    if (data.isItNoStoppingZone) {
      return 'You can not park there';
    }

    if (!data.isthereAParkingRestriction) {
      return 'There are not parking restrictions';
    }
    let hrLabel = `hr`;
    if (stringToInt(data.forHowLongCanIPark) ?? 0 > 1) {
      hrLabel += `s`;
    }

    let paymentRequired = '';
    if (data.doINeedATicket) {
      paymentRequired = 'with a ticket';
    }
    return `You can park here for ${data.forHowLongCanIPark} ${hrLabel} ${paymentRequired}`;
  }, [data]);

  const renderAccuracyButton = () => {
    if (isFeedbackSend) {
      return <h6>Thank you for your feedback!</h6>;
    }
    return (
      <AccuracyButton>
        <span>Was this response accurate?</span>
        <EmojiButtonContainer>
          <EmojiButton
            role="img"
            aria-label="thumbs up"
            onClick={() => handleFeedback('positive')}
          >
            &#128077;
          </EmojiButton>
          <EmojiButton
            role="img"
            aria-label="thumbs down"
            onClick={() => handleFeedback('negative')}
          >
            &#128078;
          </EmojiButton>
        </EmojiButtonContainer>
      </AccuracyButton>
    );
  };

  return (
    <>
      <LightImage imageName={getLightImage} />
      <Container>
        <Date>
          <span className="bold">It's {timestamp.date}</span>
          <br />
          at {timestamp.time}
        </Date>
        <Result>
          <span className="bold">
            <span> {renderRestrictions}</span>
            <ImageStyled
              src={info}
              alt="what does this mean?"
              onClick={toggleModal}
            />
          </span>
        </Result>
        {renderAccuracyButton()}
      </Container>
      {isModalOpen && (
        <ModalOverlay show={isModalOpen}>
          <ModalContent>
            <CloseButton onClick={toggleModal}>&times;</CloseButton>
            <p>
              {data.smallSummary}
              <br />
              <br />
              Use this as a guide; always verify and seek additional signage for
              accuracy.
            </p>
          </ModalContent>
        </ModalOverlay>
      )}
    </>
  );
};

export default ParkingStatus;
