import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import Header from '../components/header';

type Props = {
  shouldDisplayFooter?: boolean;
};

const Container = styled.div`
  background: rgb(129, 118, 255);
  background: linear-gradient(
    150deg,
    rgba(129, 118, 255, 1) 0%,
    rgba(189, 58, 196, 1) 100%
  );
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  color: white;
  border-radius: 0 0 100px 0;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 85vw;
`;

const Footer = styled.footer`
  height: 15vh;
  margin-top: -5vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding-left: 7.4vw;
  color: #414243;
  width: 80vw;
`;

const IntroLayout: React.FC<Props> = ({ shouldDisplayFooter = false }) => {
  return (
    <>
      <Header />
      <Container>
        <Content>
          <Outlet />
        </Content>
      </Container>
      {shouldDisplayFooter && (
          <Footer>
            <p>
              Use this as a guide; always verify and seek additional signage for
              accuracy.
            </p>
          </Footer>
        )}
    </>
  );
};

IntroLayout.defaultProps = {
  shouldDisplayFooter: false,
};

export default IntroLayout;
