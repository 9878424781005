const LoadingAnimation = () => (
  <svg version="1.1" id="L5" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 100 60" // Adjusted viewBox to better fit the circles and animation.
    style={{ width: '100px', height: '60px', display: 'block' }}> // Ensure SVG has a size and is block-level
    <circle fill="#fff" stroke="none" cx="15" cy="30" r="6"> // Adjusted positions to be more centered
      <animateTransform 
         attributeName="transform" 
         dur="1s" 
         type="translate" 
         values="0 15; 0 -15; 0 15" 
         repeatCount="indefinite" 
         begin="0.1s"/>
    </circle>
    <circle fill="#fff" stroke="none" cx="50" cy="30" r="6">
      <animateTransform 
         attributeName="transform" 
         dur="1s" 
         type="translate" 
         values="0 10; 0 -10; 0 10" 
         repeatCount="indefinite" 
         begin="0.2s"/>
    </circle>
    <circle fill="#fff" stroke="none" cx="85" cy="30" r="6">
      <animateTransform 
         attributeName="transform" 
         dur="1s" 
         type="translate" 
         values="0 5; 0 -5; 0 5" 
         repeatCount="indefinite" 
         begin="0.3s"/>
    </circle>
  </svg>
);

export default LoadingAnimation;
