import { Outlet } from 'react-router-dom';
import styled from '@emotion/styled';
import Header from '../components/header';

const Container = styled.div`
  text-align: center;
`;

const IntroLayout: React.FC = () => {
  return (
    <>
      <Header />
      <Container>
        <Outlet />
      </Container>
    </>
  );
};

export default IntroLayout;
