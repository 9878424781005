import styled from '@emotion/styled';
import React, { useRef, useCallback } from 'react';
import Webcam from 'react-webcam';
import LoadingAnimation from '../composites/loadingAnimation';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  row-gap: 10px;
`;

const WebCamContainer = styled.div`
  position: relative;
`;

const VerticalOverlay = styled.div<{ isHidden: boolean }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 10px; /* Adjust thickness as needed */
  height: calc(100% - 4px);
  background-color: rgba(255, 255, 0, 0.5);
  z-index: 1;
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
`;

const videoConstraints = {
  width: 720,
  height: 980,
  facingMode: 'environment',
};

interface Props {
  setSignPhoto: React.Dispatch<React.SetStateAction<string | null>>;
}

const CameraInput: React.FC<Props> = (props: Props) => {
  const { setSignPhoto } = props;
  const webcamRef = useRef<Webcam>(null);
  const [loading, setLoading] = React.useState(true);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const handleUserMedia = () => {
    setLoading(false);
  };

  const drawYellowLine = (
    context: CanvasRenderingContext2D,
    x: number,
    y: number,
    length: number
  ) => {
    context.beginPath();
    context.moveTo(x, y);
    context.lineTo(x, y + length);
    context.strokeStyle = 'rgba(255, 255, 0, 0.5)';
    context.lineWidth = 15;
    context.stroke();
  };

  const capturePhoto = useCallback(() => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        const canvas = canvasRef.current;
        if (!canvas) {
          return;
        }
        const context = canvas.getContext('2d');
        if (!context) {
          return;
        }

        const img = new Image();
        img.onload = () => {
          // add yellow line to the image
          context.drawImage(img, 0, 0, canvas.width, canvas.height);
          drawYellowLine(context, canvas.width / 2, 0, canvas.height);
          const modifiedImageBase64 = canvas.toDataURL('image/jpeg');
          setSignPhoto(modifiedImageBase64);
        };
        img.src = imageSrc;
      }
    }
  }, [webcamRef]);

  const Loader = () => {
    return <LoadingAnimation />;
  };

  return (
    <>
      <Container>
        {loading && <Loader />}
        <WebCamContainer>
          <VerticalOverlay isHidden={loading} />
          <Webcam
            audio={false}
            onUserMedia={handleUserMedia}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            style={{
              height: '100%',
              width: '100%',
            }}
            videoConstraints={videoConstraints}
          />
          <canvas
            ref={canvasRef}
            style={{ display: 'none' }}
            width={videoConstraints.width}
            height={videoConstraints.height}
          />
        </WebCamContainer>
        <button onClick={capturePhoto}>Capture Photo</button>
      </Container>
    </>
  );
};

export default CameraInput;
