import logo from '../assets/logo.svg';
import styled from '@emotion/styled';
import SignInButton from '../components/signInButton';

const Container = styled.div`
  background: rgb(129, 118, 255);
  background: linear-gradient(
    150deg,
    rgba(129, 118, 255, 1) 0%,
    rgba(189, 58, 196, 1) 100%
  );
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
`;

const Content = styled.div`
  margin-top: -35px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

const LogoStyled = styled.img`
  height: 40vmin;
  max-height: 200px;
  pointer-events: none;
`;

const Login: React.FC = () => {
  return (
    <Container>
      <Content>
        <LogoStyled src={logo} alt="logo" />
        <br />
        <SignInButton />
      </Content>
    </Container>
  );
};

export default Login;
