import styled from '@emotion/styled';
import chevronWhite from '../assets/chevron-white.svg';
import chevronPurple from '../assets/chevron-purple.svg';

const ButtonContainer = styled.div`
  display: flex;
  height: 20vh;
  width: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
`;

const commonButtonStyles = `
  height: 100%;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  width: 48%;
`;

const LeftButton = styled.button`
  ${commonButtonStyles}
  &::after {
    content: none;
  }
  &:hover::after {
    content: none;
  }
  &::before {
    content: url('${chevronPurple}');
    transform: rotate(180deg);
    margin-right: 10px;
    margin-top: 4px;
  }
  &:hover::before {
    content: url('${chevronWhite}');
    transform: rotate(180deg);
  }
`;

const RightButton = styled.button`
  ${commonButtonStyles}
  &::after {
    content: url('${chevronPurple}');
    margin-left: 10px;
    margin-top: -5px;
  }
  &:hover::after {
    content: url('${chevronWhite}');
  }
`;

interface Props {
  setParkingSide: React.Dispatch<React.SetStateAction<string | null>>;
}

const ParkingSideButtons: React.FC<Props> = (props: Props) => {
  const { setParkingSide } = props;

  const handleOnClickSide = (side: string) => {
    setParkingSide(side);
  };

  return (
    <ButtonContainer>
      <LeftButton onClick={() => handleOnClickSide('left')}>LEFT</LeftButton>
      <RightButton onClick={() => handleOnClickSide('right')}>
        RIGHT
      </RightButton>
    </ButtonContainer>
  );
};

export default ParkingSideButtons;
