import styled from '@emotion/styled';
import refresh from '../assets/camerarefresh.svg';

const RefreshButton = styled.img`
  height: 45px;
  background-color: #fff;
  border-radius: 100px;
  padding: 5px;
  margin-left: -30px;
`;

interface Props {
  resetSignPhoto: React.Dispatch<React.SetStateAction<string | null>>;
}

const RefreshSignButton: React.FC<Props> = (props: Props) => {
  const { resetSignPhoto } = props;
  const handleOnTakeSignPhoto = () => {
    resetSignPhoto(null);
  };

  return (
    <RefreshButton
      src={refresh}
      onClick={handleOnTakeSignPhoto}
      alt="refresh"
    />
  );
};

export default RefreshSignButton;
