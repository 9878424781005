import { ReactNode, useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { AuthContext } from '../providers/authProvider';
import LoadingAnimation from '../composites/loadingAnimation';

type Props = {
  children: ReactNode;
};

const PrivateRoute = ({ children }: Props): JSX.Element => {
  const { loading, user } = useContext(AuthContext);

  if (loading) {
    return <LoadingAnimation />;
  }

  if (user) {
    return <>{children}</>;
  }

  return <Navigate to="/" />;
};

PrivateRoute.propTypes = {
  children: PropTypes.node,
};

export default PrivateRoute;
